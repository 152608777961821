import React from "react";
import SEO from "@components/common/seo";
import Layout from "@components/layout";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import bonus from "@components/images/baji999/baji999-bonus.webp";
import soccer from "@components/images/baji999/baji999-soccer.webp";
import basketball from "@components/images/baji999/baji999-basketball.webp";
import kabaddi from "@components/images/baji999/baji999-kabaddi.webp";
import cricket from "@components/images/baji999/baji999-cricket.webp";
import horseRacing from "@components/images/baji999/baji999-horse-racing.webp";
import virtualSports from "@components/images/baji999/baji999-virtual-sports.webp";
import banner2 from "@components/images/baji999/baji999-1536x431.webp";

import registrationBonus from "@components/images/baji999/baji999-registration-bonus.webp";
import firstDeposit from "@components/images/baji999/baji999-bonus-for-first-deposit.webp";
import activityBonus from "@components/images/baji999/baji999-gaming-activity-bonus.webp";
import refferalBonus from "@components/images/baji999/baji999-refferal-bonus.webp";
import topUp from "@components/images/baji999/baji999-top-up.webp";
import reloadBonus from "@components/images/baji999/baji999-reload-bonus.webp";
import cashback from "@components/images/baji999/baji999-cashback.webp";

import leaderboard from "@components/images/baji999/baji999-seasonal-leaderboard.webp";
import VIPPoints from "@components/images/baji999/baji999-VIP-points.webp";
import VIPManager from "@components/images/baji999/baji999-VIP-manager.webp";
import VIPRewards from "@components/images/baji999/baji999-VIP-exclusive-rewards.webp";
import VIPPayment from "@components/images/baji999/baji999-VIP-payment-channel.webp";

import register from "@components/images/baji999/baji999-register.webp";
import slots from "@components/images/baji999/baji999-slots.webp";
import tableGames from "@components/images/baji999/baji999-table-games.webp";
import jackpots from "@components/images/baji999/baji999-jackpots.webp";
import fishing from "@components/images/baji999/baji999-fishing.webp";
import liveDealer from "@components/images/baji999/baji999-live-dealer-casino.webp";

import app from "@components/images/baji999/baji999-application.webp";

import withLocation from "@helpers/hoc/withLocation";

const Baji999 = ({ location }: any) => {
  return (
    <Layout location={location.pathname}>
      <div className="bg-[white]">
        <SEO
          title="Baji999 Live - Cricket Betting & Live Casino & Bonuses"
          description="Baji999 is the perfect place to gamble online. Cricket betting, live casino and more. Claim your 100% welcome bonus and enjoy the game!"
        />

        <div className="bg-[url('@components/images/baji999/bg1.webp')] bg-no-repeat bg-cover">
          <div className="content-container p-[15px]">
            <h2 className="text-2xl text-center text-white">
              Baji999 Live - Casino & Sports Betting
            </h2>
            <div className="grid items-center md:grid-cols-2">
              <div className="flex flex-col items-center">
                <img src={bonus} alt="bonus" className="w-[350px] mb-0" />
                <h3 className="my-2 text-lg italic font-medium text-white md:text-2xl">
                  Welcome Offer - 100% First Deposit Bonus
                </h3>
                <button className="flex items-center justify-center bg-gradient-to-r from-[#f69501] to-[#fbc004] rounded-[5px] text-lg h-[45px] px-[24px] text-white my-3">
                  Pick Up Bonus
                </button>
              </div>
              <p className="text-sm text-center text-white md:text-lg">
                <span className="font-bold">Baji999</span> is a gaming platform
                based in Bangladesh, which is becoming increasingly popular
                among gamers not only in that country, but also in almost all of
                South East Asia and India. It offers an exciting array of games
                and features for players who want to test their skills and
                challenge themselves. From classic board games like chess and
                mahjong, to exciting adventures in virtual worlds, Baji999 has
                something for everyone. The graphics are stunningly realistic,
                the sound effects are realistic and the gameplay is smooth and
                enjoyable.
              </p>
            </div>
          </div>
        </div>

        <div className="bg-[#353535]">
          <div className="content-container md:px-[15px]">
            <Accordion className="!bg-[#353535] md:w-[70%] mx-auto !rounded-none !shadow-none">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className="text-[#fff]" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="!border-b-[1px] !border-[#fff] !border-solid"
              >
                <h2 className="text-[#fff]">Content</h2>
              </AccordionSummary>
              <AccordionDetails>
                <ul className="list-item text-[#fff]">
                  <li className="list-disc">
                    <a href="" className="text-lg">
                      Baji999 Review
                    </a>
                  </li>
                  <li className="list-disc">
                    <a href="" className="text-lg">
                      Bonuses and Promotions
                    </a>
                  </li>
                  <li className="list-disc">
                    <a href="" className="text-lg">
                      Vip-Club
                    </a>
                  </li>
                  <li className="list-disc">
                    <a href="" className="text-lg">
                      Registration Process
                    </a>
                  </li>
                  <li className="list-disc">
                    <a href="" className="text-lg">
                      Advantages and Disadvantages
                    </a>
                  </li>
                  <li className="list-disc">
                    <a href="" className="text-lg">
                      Online Casino
                    </a>
                  </li>
                  <li className="list-disc">
                    <a href="" className="text-lg">
                      Sports Betting
                    </a>
                  </li>
                  <li className="list-disc">
                    <a href="" className="text-lg">
                      Application
                    </a>
                  </li>
                  <li className="list-disc">
                    <a href="" className="text-lg">
                      Deposit Methods
                    </a>
                  </li>
                  <li className="list-disc">
                    <a href="" className="text-lg">
                      Security
                    </a>
                  </li>
                  <li className="list-disc">
                    <a href="" className="text-lg">
                      Technical Customer Service
                    </a>
                  </li>
                  <li className="list-disc">
                    <a href="" className="text-lg">
                      FAQ
                    </a>
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>

            <div className="bg-[#484848] mt-4 py-4">
              <div className="grid grid-cols-3">
                <div>
                  <img
                    className="mb-0 w-[70%] mx-auto duration-[.3s] hover:scale-[.9]"
                    src={soccer}
                    alt="soccer"
                  />
                  <p className="mb-0 text-sm text-center text-white uppercase md:text-lg">
                    soccer
                  </p>
                </div>
                <div>
                  <img
                    className="mb-0 w-[70%] mx-auto duration-[.3s] hover:scale-[.9]"
                    src={basketball}
                    alt="basketball"
                  />
                  <p className="mb-0 text-sm text-center text-white uppercase md:text-lg">
                    basketball
                  </p>
                </div>
                <div>
                  <img
                    className="mb-0 w-[70%] mx-auto duration-[.3s] hover:scale-[.9]"
                    src={kabaddi}
                    alt="kabaddi"
                  />
                  <p className="mb-0 text-sm text-center text-white uppercase md:text-lg">
                    kabaddi
                  </p>
                </div>
                <div>
                  <img
                    className="mb-0 w-[70%] mx-auto duration-[.3s] hover:scale-[.9]"
                    src={cricket}
                    alt="cricket"
                  />
                  <p className="mb-0 text-sm text-center text-white uppercase md:text-lg">
                    cricket
                  </p>
                </div>
                <div>
                  <img
                    className="mb-0 w-[70%] mx-auto duration-[.3s] hover:scale-[.9]"
                    src={horseRacing}
                    alt="horse racing"
                  />
                  <p className="mb-0 text-sm text-center text-white uppercase md:text-lg">
                    horse racing
                  </p>
                </div>
                <div>
                  <img
                    className="mb-0 w-[70%] mx-auto duration-[.3s] hover:scale-[.9]"
                    src={virtualSports}
                    alt="virtual sports"
                  />
                  <p className="mb-0 text-sm text-center text-white uppercase md:text-lg">
                    virtual sports
                  </p>
                </div>
              </div>

              <div className="border-y-[5px] border-[#353535] mt-5 py-5 mx-3">
                <h2 className="mb-3 text-2xl text-center text-white">
                  Baji 999 Review
                </h2>
                <p className="mb-4 text-sm text-white md:text-lg">
                  A gaming platform that offers a huge variety of games for its
                  customers. The platform has many categories ranging from
                  adventure and fighting games to classic board games, betting
                  on sports, cyber sports and more. It also includes a large
                  selection of multiplayer options, so gamers can compete
                  against each other in real time.
                </p>
                <p className="mb-4 text-sm text-white md:text-lg">
                  The game’s graphics are realistic and fluid, making the
                  gameplay very immersive. Thanks to the simple yet effective
                  controls, players can maneuver through the different levels
                  with ease. In addition, the background music is also pleasant
                  and soothing, which adds to the atmosphere when playing your
                  favourite game.
                </p>
                <p className="mb-4 text-sm text-white md:text-lg">
                  Overall, Baji999 is a great platform that allows people to
                  enjoy exciting games from the comfort of their own home. It is
                  easy to use, has an extensive selection of games and offers a
                  great multiplayer experience. Highly recommended for all
                  gamers!
                </p>
                <img className="mb-0" src={banner2} alt="banner" />
              </div>

              <div className="border-b-[5px] border-[#353535] mt-5 pb-5 mx-3">
                <table className="w-[100%]">
                  <thead className="">
                    <tr>
                      <th className="border-[2px] border-[#767676] w-[50%] uppercase p-2 md:p-3 text-sm md:text-lg text-white">
                        Official name
                      </th>
                      <th className="border-[2px] border-[#767676] w-[50%] uppercase p-2 md:p-3 text-sm md:text-lg text-white">
                        Baji999
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border-[2px] border-[#767676] w-[50%] uppercase p-2 md:p-3 text-sm md:text-lg text-white">
                        Licence
                      </td>
                      <td className="border-[2px] border-[#767676] w-[50%] uppercase p-2 md:p-3 text-sm md:text-lg text-white">
                        Curacao
                      </td>
                    </tr>
                    <tr>
                      <td className="border-[2px] border-[#767676] w-[50%] uppercase p-2 md:p-3 text-sm md:text-lg text-white">
                        Year founded
                      </td>
                      <td className="border-[2px] border-[#767676] w-[50%] uppercase p-2 md:p-3 text-sm md:text-lg text-white">
                        2021
                      </td>
                    </tr>
                    <tr>
                      <td className="border-[2px] border-[#767676] w-[50%] uppercase p-2 md:p-3 text-sm md:text-lg text-white">
                        Number of sports
                      </td>
                      <td className="border-[2px] border-[#767676] w-[50%] uppercase p-2 md:p-3 text-sm md:text-lg text-white">
                        20+
                      </td>
                    </tr>
                    <tr>
                      <td className="border-[2px] border-[#767676] w-[50%] uppercase p-2 md:p-3 text-sm md:text-lg text-white">
                        Mobile Application
                      </td>
                      <td className="border-[2px] border-[#767676] w-[50%] uppercase p-2 md:p-3 text-sm md:text-lg text-white">
                        Android
                      </td>
                    </tr>
                    <tr>
                      <td className="border-[2px] border-[#767676] w-[50%] uppercase p-2 md:p-3 text-sm md:text-lg text-white">
                        Support
                      </td>
                      <td className="border-[2px] border-[#767676] w-[50%] uppercase p-2 md:p-3 text-sm md:text-lg text-white">
                        24/7
                      </td>
                    </tr>
                    <tr>
                      <td className="border-[2px] border-[#767676] w-[50%] uppercase p-2 md:p-3 text-sm md:text-lg text-white">
                        Customer support
                      </td>
                      <td className="border-[2px] border-[#767676] w-[50%] uppercase p-2 md:p-3 text-sm md:text-lg text-white">
                        Email, Live chat and social media
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="border-b-[5px] border-[#353535] py-5 mx-3">
                <h2 className="mb-3 text-2xl text-center text-white">
                  Bonuses & Promotions
                </h2>
                <p className="mb-4 text-sm text-white md:text-lg">
                  Casino Baji.999 offers cash bonuses, freespins and comps to
                  both new users and regular customers. This allows gamers to
                  get used to the gambling establishment and actively gamble at
                  virtually no cost. The site provides several varieties of
                  bonuses. Bonuses are offered to club members both on a regular
                  basis and as part of short-term promotions. Baji999 offers the
                  following main bonuses:
                </p>
                <div className="flex flex-wrap">
                  <div className="flex-grow-1 w-[50%] md:w-[25%] !p-4">
                    <img
                      className="mb-2 w-[30%] md:w-[25%] mx-auto duration-[.3s] hover:scale-[.9]"
                      src={registrationBonus}
                      alt="registration bonus"
                    />
                    <p className="mb-0 text-sm text-center text-white uppercase md:text-lg">
                      FOR REGISTRATION
                    </p>
                  </div>
                  <div className="flex-grow-1 w-[50%] md:w-[25%] !p-4">
                    <img
                      className="mb-2 w-[30%] md:w-[25%] mx-auto duration-[.3s] hover:scale-[.9]"
                      src={firstDeposit}
                      alt="first deposit"
                    />
                    <p className="mb-0 text-sm text-center text-white uppercase md:text-lg">
                      FOR FIRST DEPOSIT
                    </p>
                  </div>
                  <div className="flex-grow-1 w-[50%] md:w-[25%] !p-4">
                    <img
                      className="mb-2 w-[30%] md:w-[25%] mx-auto duration-[.3s] hover:scale-[.9]"
                      src={activityBonus}
                      alt="activity bonus"
                    />
                    <p className="mb-0 text-sm text-center text-white uppercase md:text-lg">
                      FOR GAMING ACTIVITY
                    </p>
                  </div>
                  <div className="flex-grow-1 w-[50%] md:w-[25%] !p-4">
                    <img
                      className="mb-2 w-[30%] md:w-[25%] mx-auto duration-[.3s] hover:scale-[.9]"
                      src={refferalBonus}
                      alt="refferal bonus"
                    />
                    <p className="mb-0 text-sm text-center text-white uppercase md:text-lg">
                      REFFERAL BONUS
                    </p>
                  </div>
                  <div className="flex-grow-1 w-[50%] md:w-[25%] !p-4">
                    <img
                      className="mb-2 w-[30%] md:w-[16%] mx-auto duration-[.3s] hover:scale-[.9]"
                      src={topUp}
                      alt="top up"
                    />
                    <p className="mb-0 text-sm text-center text-white uppercase md:text-lg">
                      TOP UP WITH A CERTAIN AMOUNT
                    </p>
                  </div>
                  <div className="flex-grow-1 w-[50%] md:w-[25%] !p-4">
                    <img
                      className="mb-2 w-[30%] md:w-[16%] mx-auto duration-[.3s] hover:scale-[.9]"
                      src={reloadBonus}
                      alt="reload bonus"
                    />
                    <p className="mb-0 text-sm text-center text-white uppercase md:text-lg">
                      RELOAD BONUS UP TO 10,000 BDT
                    </p>
                  </div>
                  <div className="flex-grow-1 w-[50%] md:w-[25%] !p-4">
                    <img
                      className="mb-2 w-[20%] md:w-[16%] mx-auto duration-[.3s] hover:scale-[.9]"
                      src={cashback}
                      alt="cash back"
                    />
                    <p className="mb-0 text-sm text-center text-white uppercase md:text-lg">
                      CASHBACK
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-[#000]">
              <div className="!py-5 mx-3">
                <h2 className="mb-3 text-2xl text-center text-white">
                  Vip-Club
                </h2>
                <div className="flex flex-wrap justify-center">
                  <img
                    className="mb-2 p-[10px] md:w-[33%] mx-auto duration-[.3s] hover:scale-[.9]"
                    src={leaderboard}
                    alt="leaderboard"
                  />
                  <img
                    className="mb-2 p-[10px] md:w-[33%] mx-auto duration-[.3s] hover:scale-[.9]"
                    src={VIPPoints}
                    alt="VIP points"
                  />
                  <img
                    className="mb-2 p-[10px] md:w-[33%] mx-auto duration-[.3s] hover:scale-[.9]"
                    src={VIPManager}
                    alt="VIP manager"
                  />
                  <img
                    className="mb-2 p-[10px] md:w-[33%] duration-[.3s] hover:scale-[.9]"
                    src={VIPRewards}
                    alt="VIP rewards"
                  />
                  <img
                    className="mb-2 p-[10px] md:w-[33%] duration-[.3s] hover:scale-[.9]"
                    src={VIPPayment}
                    alt="VIP payment"
                  />
                </div>
                <p className="mb-4 text-sm text-white md:text-lg">
                  The Baji999 VIP Club is the ultimate gaming experience for
                  gamers who want to take their gaming to the next level. As a
                  member of the VIP Club, you’ll have access to exclusive
                  rewards and privileges that are only available to members.
                  With special discounts, exclusive tournaments and
                  competitions, and more, being part of this elite group of
                  gamers will truly be an unforgettable experience.{" "}
                </p>
                <p className="mb-4 text-sm text-white md:text-lg">
                  As a VIP Club member, you’ll receive special discounts on all
                  purchases made through Baji 999. You’ll also get priority
                  access to all upcoming events before anyone else in addition
                  to occasional freebies like game time or credits.
                  Additionally, VIP members can participate in exclusive
                  tournaments where they can win prizes such as gaming hardware
                  and gift cards.
                </p>
                <p className="mb-4 text-sm text-white md:text-lg">
                  You’ll also be part of a vibrant and passionate gaming
                  community with the VIP Club. You’ll have access to exclusive
                  forums, chats, and more so you can talk to fellow gamers and
                  share tips and strategies on how to excel in your favorite
                  games. And if that wasn’t enough, VIP members will receive
                  exclusive in-game rewards every month just for being part of
                  the club.
                </p>
              </div>
            </div>

            <div className="bg-[#484848] py-4">
              <div className="border-y-[5px] border-[#353535] py-4 mx-3">
                <h2 className="mb-3 text-2xl text-center text-white">
                  Baji999 Registration Process
                </h2>
                <p className="mb-4 text-sm text-white md:text-lg">
                  Anyone may register at the Baji999 online gaming platform. The
                  main thing is that he must be of legal age. Any profiles found
                  to be underage will be irrevocably blocked. The same fate
                  awaits those who try to create more than one Personal Area. In
                  some cases all products will be blocked.
                </p>
                <p className="mb-4 text-sm text-white md:text-lg">
                  To create a profile, use the standard electronic form. Users
                  should find the “Sign Up” button in the app or on the original
                  resource page and fill out the electronic form.
                </p>
                <p className="mb-4 text-sm text-white md:text-lg">
                  Baji999 requires you to:
                </p>
                <ul className="list-item text-[#fff]">
                  <li className="text-sm list-disc md:text-lg">
                    Write a username;
                  </li>
                  <li className="text-sm list-disc md:text-lg">
                    Think of a strong password;
                  </li>
                  <li className="text-sm list-disc md:text-lg">
                    Choose your preferred currency (BDT, INR or PKR){" "}
                  </li>
                  <li className="text-sm list-disc md:text-lg">
                    If there is a Refer Code, be sure to use it;
                  </li>
                  <li className="text-sm list-disc md:text-lg">
                    Then, going to another stage of registration, you need to
                    fill data such as full name, e-mail and phone number;
                  </li>
                  <li className="text-sm list-disc md:text-lg">
                    You need to confirm your age and you can complete your
                    registration.
                  </li>
                </ul>
                <button className="flex items-center justify-center mx-auto bg-gradient-to-r from-[#f69501] to-[#fbc004] rounded-[5px] text-lg h-[45px] px-[24px] text-white mt-3">
                  Register Now
                </button>
                <img
                  className="my-3 w-[60%] mx-auto"
                  src={register}
                  alt="register"
                />

                <h2 className="mb-3 text-2xl text-center text-white">
                  Baji999 Login
                </h2>
                <p className="mb-4 text-sm text-white md:text-lg">
                  Logging in to your gaming platform account allows you to keep
                  track of all the games and content you have access to. It also
                  makes it easy to view your rewards, purchase additional
                  content, and make changes to your profile information.{" "}
                </p>
                <p className="mb-4 text-sm text-white md:text-lg">
                  To log into your personal account, simply click on the ‘Login’
                  link located at the top right corner of any page on the
                  website. You will then be prompted to enter your username and
                  password. After entering this information, click ‘Login’ to
                  gain access to all the features available within your gaming
                  account.{" "}
                </p>
                <p className="mb-4 text-sm text-white md:text-lg">
                  If you are having difficulty logging in or need assistance
                  with any aspect of the site, please contact our customer
                  service team for further assistance. We are here to ensure you
                  have the best possible gaming experience!
                </p>

                <h2 className="mt-5 mb-3 text-2xl text-center text-white">
                  Account Verification
                </h2>
                <p className="mb-4 text-sm text-white md:text-lg">
                  Account verification is an important part of the gaming
                  platform. It helps to ensure the safety of players, as well as
                  guarantee the legitimacy of all transactions. By verifying
                  user accounts, Baji999 ensures that any person who plays on
                  its platform is of legal age and can act in accordance with
                  the agreed terms and conditions.
                </p>
                <p className="mb-4 text-sm text-white md:text-lg">
                  Account verification involves providing personal
                  identification documents such as passport, driving license,
                  etc.
                </p>
                <p className="mb-4 text-sm text-white md:text-lg">
                  The verification process is also designed to protect users
                  from fraud or theft when transferring funds. By verifying the
                  account, Baji999 can confirm that the user really is who they
                  say they are, and that their card details and banking
                  information has not been stolen or compromised in any way.
                  This ensures that all money transfers are safe and secure from
                  intruders.
                </p>
                <p className="mb-4 text-sm text-white md:text-lg">
                  n addition, the verification process also helps to keep
                  players accountable by preventing them from creating multiple
                  accounts with different names or using false names to
                  participate in games on the platform. Baji999 is always
                  committed to protecting its users and takes all measures
                  necessary to ensure their safety and security while playing on
                  its platform.
                </p>
              </div>

              <div className="border-b-[5px] border-[#353535] pb-4 mt-4 mx-3">
                <h2 className="mb-3 text-2xl text-center text-white">
                  Advantages & Disadvantages Of The Gaming Platform Baji99
                </h2>
                <div className="md:flex">
                  <table className="md:w-[50%] md:m-3">
                    <thead className="">
                      <tr>
                        <th className="border-[2px] text-center border-[#767676] w-[50%] uppercase p-3 text-sm md:text-lg text-white">
                          ADVANTAGES
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="border-[2px] border-[#767676] w-[50%] uppercase p-3 text-sm md:text-lg text-white">
                          GENEROUS BONUS PROMOTIONS
                        </td>
                      </tr>
                      <tr>
                        <td className="border-[2px] border-[#767676] w-[50%] uppercase p-3 text-sm md:text-lg text-white">
                          IMPRESSIVE RANGE OF GAMES
                        </td>
                      </tr>
                      <tr>
                        <td className="border-[2px] border-[#767676] w-[50%] uppercase p-3 text-sm md:text-lg text-white">
                          HIGH PERCENTAGE OF RETURNS ON SLOT MACHINES
                        </td>
                      </tr>
                      <tr>
                        <td className="border-[2px] border-[#767676] w-[50%] uppercase p-3 text-sm md:text-lg text-white">
                          EXCELLENT LEVEL OF SECURITY
                        </td>
                      </tr>
                      <tr>
                        <td className="border-[2px] border-[#767676] w-[50%] uppercase p-3 text-sm md:text-lg text-white">
                          LICENCE
                        </td>
                      </tr>
                      <tr>
                        <td className="border-[2px] border-[#767676] w-[50%] uppercase p-3 text-sm md:text-lg text-white">
                          PROCESSING TIME FROM 15-30 MINUTES
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="md:w-[50%] mt-3 md:m-3 h-[90px]">
                    <thead className="">
                      <tr>
                        <th className="border-[2px] text-center border-[#767676] w-[50%] uppercase p-3 text-sm md:text-lg text-white">
                          DISADVANTAGES
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="border-[2px] border-[#767676] w-[50%] uppercase p-3 text-sm md:text-lg text-white">
                          LIMITED PAYMENT OPTIONS
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="border-b-[5px] border-[#353535] py-5 mx-3">
                <h2 className="mb-3 text-2xl text-center text-white">
                  Online Casino Range
                </h2>
                <p className="mb-4 text-sm text-white md:text-lg">
                  The online casino features a selection of the best gambling
                  machines. All the software is supplied by more than 20
                  providers. The slots and slot machines in the electronic
                  catalogue are carefully selected and licensed stable software.
                  It cannot be outsmarted, hacked or tampered with. The payout
                  in case of winnings is only affected by the payout percentage.
                  Integrity of virtual simulators provided by a stable random
                  number generator.
                </p>
                <p className="mb-4 text-sm text-white md:text-lg">
                  In the catalogue of the online site can meet:
                </p>
                <div className="flex flex-wrap mx-3">
                  <div className="flex-grow-1 w-[50%] !p-4">
                    <img
                      className="mb-2 w-[50%] mx-auto duration-[.3s] hover:scale-[.9]"
                      src={slots}
                      alt="slots"
                    />
                    <p className="mb-0 text-sm text-center text-white uppercase md:text-lg">
                      slots
                    </p>
                  </div>
                  <div className="flex-grow-1 w-[50%] !p-4">
                    <img
                      className="mb-2 w-[50%] mx-auto duration-[.3s] hover:scale-[.9]"
                      src={tableGames}
                      alt="table games"
                    />
                    <p className="mb-0 text-sm text-center text-white uppercase md:text-lg">
                      table games
                    </p>
                  </div>
                  <div className="flex-grow-1 w-[50%] !p-4">
                    <img
                      className="mb-2 w-[50%] mx-auto duration-[.3s] hover:scale-[.9]"
                      src={jackpots}
                      alt="jackpots"
                    />
                    <p className="mb-0 text-sm text-center text-white uppercase md:text-lg">
                      jackpots
                    </p>
                  </div>
                  <div className="flex-grow-1 w-[50%] !p-4">
                    <img
                      className="mb-2 w-[50%] mx-auto duration-[.3s] hover:scale-[.9]"
                      src={fishing}
                      alt="fishing"
                    />
                    <p className="mb-0 text-sm text-center text-white uppercase md:text-lg">
                      fishing
                    </p>
                  </div>
                  <div className="flex-grow-1 w-[50%] !p-4">
                    <img
                      className="mb-2 w-[20%] mx-auto duration-[.3s] hover:scale-[.9]"
                      src={liveDealer}
                      alt="live dealer"
                    />
                    <p className="mb-0 text-sm text-center text-white uppercase md:text-lg">
                      live Dealers
                    </p>
                  </div>
                </div>

                <div className="grid-cols-2 md:grid">
                  <div className="md:p-3">
                    <h2 className="mb-3 text-center md:!text-left text-2xl text-white">
                      Slots
                    </h2>
                    <p className="mb-0 text-sm text-white md:text-lg">
                      Slots at Baji999 is the most played and most popular
                      section in the casino. They offer an unrivalled array of
                      exciting and rewarding gaming experiences. With a huge
                      selection of slots, from classic 3-reel to modern 5-reel
                      video slots, everyone will find something for themselves.
                      All games boast cutting-edge graphics, smooth animations
                      and sound effects, as well as exciting bonus rounds, free
                      spins, jokers and scatter symbols to really spice up your
                      gaming experience. Play games such as:
                    </p>
                  </div>
                  <div className="md:p-3">
                    <h2 className="mb-3 mt-4 md:!mt-0 text-center md:!text-left  text-2xl text-white">
                      Table Games
                    </h2>
                    <p className="mb-0 text-sm text-white md:text-lg">
                      The table games at Baji999 Casino provide players with a
                      unique and exciting experience. There are 89 games to
                      choose from to suit all tastes and ranges from the classic
                      to the modern. Whether you prefer the thrill of Teen
                      Patti, the complexity of poker or something else, there is
                      sure to be a game to suit your style.{" "}
                    </p>
                    <p className="mb-2 text-sm text-white md:text-lg">
                      The atmosphere at Baji999 is second to none. No matter
                      what game you play, you can count on high-quality
                      entertainment in a safe environment.
                    </p>
                  </div>
                </div>
                <ul className="pl-3 list-item">
                  <li className="mb-1 text-sm font-bold text-white list-disc md:text-lg">
                    Bonus Mania;
                  </li>
                  <li className="mb-1 text-sm font-bold text-white list-disc md:text-lg">
                    WorldMatch 50s PinUp;
                  </li>
                  <li className="mb-1 text-sm font-bold text-white list-disc md:text-lg">
                    Arowanas Luck;
                  </li>
                  <li className="mb-1 text-sm font-bold text-white list-disc md:text-lg">
                    Asgardian Stones;
                  </li>
                  <li className="mb-1 text-sm font-bold text-white list-disc md:text-lg">
                    Big Win.
                  </li>
                </ul>

                <h2 className="mb-3 text-center md:!text-left  text-2xl text-white mt-4">
                  Baji live 999
                </h2>
                <p className="mb-0 text-sm text-white md:text-lg">
                  Live gaming at Baji999 Casino is the perfect way to experience
                  an exciting and exhilarating gaming experience. In live games,
                  players can interact with a real dealer or croupier in real
                  time while competing against other players. Live games
                  available at Baji999 include:
                </p>
                <ul className="pl-3 my-3 list-item">
                  <li className="mb-1 text-sm font-bold text-white list-disc md:text-lg">
                    Roulette
                  </li>
                  <li className="mb-1 text-sm font-bold text-white list-disc md:text-lg">
                    Blackjack
                  </li>
                  <li className="mb-1 text-sm font-bold text-white list-disc md:text-lg">
                    Poker
                  </li>
                  <li className="mb-1 text-sm font-bold text-white list-disc md:text-lg">
                    Baccarat
                  </li>
                  <li className="mb-1 text-sm font-bold text-white list-disc md:text-lg">
                    Andar Bahar.{" "}
                  </li>
                </ul>
                <p className="mb-0 text-sm text-white md:text-lg">
                  All of these games offer great odds of winning big jackpots
                  and rewards, so players have plenty of opportunities to win!
                  Players can also enjoy exclusive bonuses and promotions by
                  playing at our live gaming tables – another way to increase
                  their winnings!
                </p>
              </div>

              <div className="border-b-[5px] border-[#353535] !py-5 mx-3">
                <h2 className="mb-3 text-2xl text-center text-white">
                  Bajii999 Sports Betting
                </h2>
                <p className="mb-4 text-sm text-white md:text-lg">
                  The site’s user interface is easy to navigate, allowing users
                  to quickly find what they are looking for. Baji999 offers a
                  wide range of sports to bet on, including football, cricket,
                  basketball, tennis, volleyball, hockey, rugby, you’ll even
                  find matches on cybersport events. It also offers in-play
                  betting, pre-match betting and futures markets.
                </p>
                <p className="mb-4 text-sm text-white md:text-lg">
                  In the catalogue of the online site can meet:
                </p>
                <p className="mb-4 text-sm text-white md:text-lg">
                  Baji999 aims to keep up with the latest trends in sports
                  betting to give you all the tools you need to make informed
                  betting decisions. The modern platform offers an intuitive
                  interface that is easy to use, and offers plenty of
                  information and statistics so that you can make informed
                  choices.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-[url('@components/images/baji999/bg1.webp')] bg-no-repeat bg-cover">
          <div className="content-container p-[15px]">
            <h2 className="text-2xl text-center text-white">Baji999 App</h2>
            <div className="md:grid grid-cols-2 items-center mt-3 md:!mt-0">
              <div>
                <p className="text-sm text-white md:text-lg">
                  The Baji999 app is installed on your Android smartphone or
                  tablet just like any other standard app. Once the download is
                  complete, gamers are offered a number of benefits. The main
                  ones are unlimited access to an account and the ability to bet
                  anywhere. In order to appreciate all the advantages of the
                  software, players need to follow 6 simple steps:
                </p>
                <ul className="list-item text-[#fff] mt-3">
                  <li className="text-sm list-disc md:text-lg">
                    Go to the official website.
                  </li>
                  <li className="text-sm list-disc md:text-lg">
                    Click on “Download for Android” at the bottom of the main
                    menu.
                  </li>
                  <li className="text-sm list-disc md:text-lg">
                    Following the instructions, install the app.
                  </li>
                  <li className="text-sm list-disc md:text-lg">
                    Launch the file and agree to the user agreement.
                  </li>
                  <li className="text-sm list-disc md:text-lg">
                    Authorize in the personal cabinet or register.
                  </li>
                  <li className="text-sm list-disc md:text-lg">
                    Deposit the account.
                  </li>
                </ul>
                <button className="flex items-center justify-center mx-auto bg-gradient-to-r from-[#f69501] to-[#fbc004] rounded-[5px] text-sm md:text-lg h-[45px] px-[24px] text-white mt-3">
                  Download App
                </button>
              </div>

              <div className="flex flex-col items-center mt-3">
                <img src={app} alt="app" className="w-[150px] md:w-[300px]" />
              </div>
            </div>
          </div>
        </div>

        <div className="bg-[#353535]">
          <div className="content-container p-[15px] bg-[#484848] !mb-14">
            <div className="border-y-[5px] border-[#353535] !py-6 mt-4 md:mx-3">
              <h2 className="mb-3 text-2xl text-center text-white">
                The Mobile Version Of The Site Baji999
              </h2>
              <p className="mb-4 text-sm text-white md:text-lg">
                The functionality of the smartphone versions is in no way
                inferior to their main site or the mobile app. The main
                difference from the desktop version is only in the visual design
                of the interface. Sections of the menu are adapted to work on
                smartphones and tablets, so they work even on a low resolution
                screen.
              </p>
              <p className="mb-4 text-sm text-white md:text-lg">
                The visual difference is that the main sections and menus of the
                games are arranged so that they are easy to use. Also, to save
                traffic, the mobile version of the Baji999 website uses fewer
                advertising posters, which gives a clear advantage in loading
                speed. All these measures allow to significantly reduce traffic
                consumption and ensure that the site is fast.{" "}
              </p>
              <p className="mb-4 text-sm text-white md:text-lg">
                The user is not disadvantaged in any way in functionality. All
                games and sports betting are launched and work without hiccups.
                Modern technology makes it possible to display high quality
                pictures even at low screen resolutions. Sometimes the graphics
                visually look even better than the desktop version. It is the
                best option for those who don’t have a desktop computer or for
                those who don’t want to install a mobile app.
              </p>
            </div>

            <div className="border-b-[5px] border-[#353535] !py-6 mt-4 md:mx-3">
              <h2 className="mb-3 text-2xl text-center text-white">
                Deposit Methods
              </h2>
              <p className="mb-4 text-sm text-white md:text-lg">
                When it comes to depositing funds into your Bangladesh Baji999
                account, you have several options to choose from. Luckily, there
                are several safe and secure deposit methods available for
                players in Bangladesh. These include:
              </p>
              <ul className="list-item text-[#fff] my-3">
                <li className="text-sm list-disc md:text-lg">
                  bKash, Rocket, Nagad
                </li>
                <li className="text-sm list-disc md:text-lg">
                  Local bank transfer. Players can use this method to transfer
                  funds directly from their local bank accounts. This is one of
                  the most popular payment methods used by players in
                  Bangladesh.
                </li>
                <li className="text-sm list-disc md:text-lg">
                  Cryptocurrency. Players can also use cryptocurrency such as
                  Tether to deposit funds into their accounts.
                </li>
              </ul>
              <p className="mb-4 text-sm text-white md:text-lg">
                These are just some of the many deposit methods available to
                players at Bangladesh Baji999. All transactions on the platform
                are secure and encrypted, so you can be sure that your funds are
                safe. Withdrawing your winnings is also easy thanks to the large
                number of withdrawal options available. So get ready to start
                playing and having fun!
              </p>
            </div>

            <div className="border-b-[5px] border-[#353535] !py-6 mt-4 md:mx-3">
              <h2 className="mb-3 text-2xl text-center text-white">
                Withdrawals
              </h2>
              <p className="mb-4 text-sm text-white md:text-lg">
                The following withdrawal methods are available in Bangladesh
                Baji.999: bKash, Nagad, Rocket and Local Bank Transfer. To
                withdraw money from your account, follow these steps:
              </p>
              <ul className="list-item text-[#fff] mt-3">
                <li className="text-sm list-decimal md:text-lg">
                  Visit the main website;
                </li>
                <li className="text-sm list-decimal md:text-lg">
                  Log in to your account;
                </li>
                <li className="text-sm list-decimal md:text-lg">
                  Go to the “Account” section;
                </li>
                <li className="text-sm list-decimal md:text-lg">
                  Select “Withdrawal” from the menu;
                </li>
                <li className="text-sm list-decimal md:text-lg">
                  Select your preferred method of payment from the list of
                  available options;
                </li>
                <li className="text-sm list-decimal md:text-lg">
                  Enter the amount you wish to withdraw and click “Next”;
                </li>
                <li className="text-sm list-decimal md:text-lg">
                  Follow the additional instructions on the screen (e.g. enter a
                  security code) and confirm that all the details you entered
                  are correct before submitting your request;
                </li>
                <li className="text-sm list-decimal md:text-lg">
                  You will be notified when your withdrawal request has been
                  processed.
                </li>
              </ul>
              <p className="mb-4 text-sm text-white md:text-lg">
                Please note that some payment methods may take longer to
                process; however, you can check the withdrawal status in the
                “Account” section of your Baji999 account. In order to withdraw
                money, you must first go through an account identification
                process, by sending your identification documents.
              </p>
            </div>

            <div className="border-b-[5px] border-[#353535] !py-6 mt-4 md:mx-3">
              <h2 className="mb-3 text-2xl text-center text-white">
                Security Of The Gaming Platform Baji999
              </h2>
              <p className="mb-4 text-sm text-white md:text-lg">
                Baji live 999 is a Bangladeshi bookmaker offering competitive
                odds in various sports betting and gambling markets. To ensure
                the security of their customers’ data and transactions, Baji.999
                uses the SSL version 3 method to encrypt the information
                transmitted between their servers and the user’s computer. This
                secure encryption technology helps to protect personal
                information such as credit card numbers, passwords, account
                details, etc. from being intercepted by cybercriminals.{" "}
              </p>
              <p className="mb-4 text-sm text-white md:text-lg">
                In addition to this, the playground has a valid license from the
                government of Curacao. This means that Baji999 operates within
                the law and is a fully legal online casino and betting site
              </p>
            </div>

            <div className="border-b-[5px] border-[#353535] !py-6 mt-4 md:mx-3">
              <h2 className="mb-3 text-2xl text-center text-white">
                Technical Customer Service
              </h2>
              <p className="mb-4 text-sm text-white md:text-lg">
                Baji 999 live provides customers with the highest quality
                technical service. A team of dedicated professionals is
                available 24/7 to help you with any technical problems you may
                encounter while gaming.{" "}
              </p>
              <p className="mb-4 text-sm text-white md:text-lg">
                The team is well versed in all aspects of online gaming
                technology, so they can help solve your problem quickly and
                accurately. If you’re having problems logging in or need help
                setting up your account, support can walk you through each
                process step by step until the problem is resolved. You can get
                in touch via live chat, email or you can write on social media
                like Facebook or Whatsapp.
              </p>
              <p className="mb-4 text-sm text-white md:text-lg">
                At Baji999, customer service is an important part of the
                gameplay and they take it very seriously. The team is there to
                help you with any questions or problems you may have. If you
                ever have a problem, don’t hesitate to contact us – we’ll be
                happy to help! There is also an extensive list of frequently
                asked questions, where you can find answers to your question to
                save you time.{" "}
              </p>
            </div>

            <div className="!py-6 mt-4 md:mx-3">
              <h2 className="mb-3 text-2xl text-white">
                Frequently Asked Questions
              </h2>
              <h2 className="mb-3 text-2xl text-white">
                How Do I Join Baji Live 999 Casino?
              </h2>
              <p className="mb-4 text-sm text-white md:text-lg">
                Joining Baji999 casino is very easy! All you have to do is
                create an account by entering your personal details and choosing
                a username and password. As soon as your account is created, you
                can start playing right away. You can also make a deposit into
                your account using one of our secure payment solutions for
                additional play money.
              </p>
              <h2 className="mb-3 text-2xl text-white">
                Are My Transactions At Baji999 Casino Secure?
              </h2>
              <p className="mb-4 text-sm text-white md:text-lg">
                Absolutely! We use the latest encryption technology to ensure
                that all your transactions are safe and secure. Your personal
                and financial information is always kept private and is never
                shared with anyone.
              </p>
              <h2 className="mb-3 text-2xl text-white">
                Are There Any Bonus Offers Available?
              </h2>
              <p className="mb-4 text-sm text-white md:text-lg">
                Yes, you’ve come to the right place! Baji999 offers incredible
                bonuses and promotions for new and existing players alike.
                Bonuses are regularly updated, so keep an eye on the site for
                the latest offers. You can also sign up for the newsletter to
                receive regular updates on all our promotions and special
                offers.
              </p>
              <h2 className="mb-3 text-2xl text-white">
                What Are The Payment Methods Accepted By Baji999 Casino?
              </h2>
              <p className="mb-4 text-sm text-white md:text-lg">
                Baji999 accepts payments in a variety of ways, including local
                bank transfers, e-wallets and cryptocurrencies. To find out more
                about the accepted payment methods, search for “payment methods”
                on the main website.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default withLocation(Baji999);
